'use client'

import { useLabels } from 'labels/hooks/useLabels/useLabels'
import { useMasterData } from 'master-data/hooks/useMasterData/useMasterData'
import { applyStylesIf, cx } from 'utils/cx'

import styles from '../DiscountRate/DiscountRate.module.scss'

export type ExtraInfoButtonProps = {
	readonly handleOnClick: () => void
	readonly horizontal: boolean
}

export const ExtraInfoButton = ({
	handleOnClick,
	horizontal,
}: ExtraInfoButtonProps) => {
	const {
		country: { isRtl },
	} = useMasterData()

	const { t } = useLabels()

	return (
		<button
			aria-label={t('accessibility.moreInfo.icon')}
			onClick={handleOnClick}
			className={cx(
				styles.buttonAccessibility,
				applyStylesIf(horizontal, styles.buttonAccesibilityReverse),
				applyStylesIf(isRtl, styles.buttonAccesibilityRtl)
			)}
		/>
	)
}
