// This component has been autogenerated with 'generate:components' script
import type { IconProps } from 'icons/types/Icon.types'
import { DEFAULT_ICON_COLOR, DEFAULT_ICON_SIZE } from 'icons/utils/constants'
import { cx } from 'utils/cx'

import styles from 'icons/Icon.module.scss'

export const IconInfoL = ({
	alt,
	className,
	color = DEFAULT_ICON_COLOR,
	height = DEFAULT_ICON_SIZE,
	lang,
	width = DEFAULT_ICON_SIZE,
	rotateRtl,
}: IconProps): React.ReactNode => {
	const computedClassName = cx(
		styles[`icon-${color}`],
		rotateRtl && styles[`rotate-rtl`],
		className
	)

	return (
		<svg
			width={width}
			height={height}
			aria-hidden={alt ? undefined : 'true'}
			className={computedClassName}
			data-testid='info-l'
			xmlns='http://www.w3.org/2000/svg'
			viewBox='0 0 12 12'
		>
			{alt && <title lang={lang}>{alt}</title>}
			<g clipPath="url('#info-l-a')">
				<path d='M6 3.667a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1m.333 1h-.666v4.666h.666z' />
				<path d='M0 0v12h12V0zm11.333 11.333H.667V.667h10.666z' />
			</g>
			<defs>
				<clipPath id='info-l-a'>
					<path d='M0 0h12v12H0z' />
				</clipPath>
			</defs>
		</svg>
	)
}
