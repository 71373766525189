'use client'

import { useLabels } from 'labels/hooks/useLabels/useLabels'

import styles from '../ExtraInfo.module.scss'

export type TitlePops = {
	readonly title: string
}
export const Title = ({ title }: TitlePops) => {
	const { t } = useLabels()
	return <p className={styles.title}>{t(title)}</p>
}
