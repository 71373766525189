import { Channel } from 'types/masterData'
import { cx } from 'utils/cx'

import styles from './DiscountRate.module.scss'
import text from 'fukku/styles/texts.module.scss'

type DiscountRateProps = {
	readonly channel: Channel
	readonly showDiscount: boolean
	readonly horizontal: boolean
	readonly align?: 'start' | 'center' | 'end'
	readonly discountText?: string
	readonly showDiscountRate?: boolean
	readonly className?: string
}

export function DiscountRate({
	align = 'start',
	channel,
	discountText,
	showDiscount,
	horizontal,
	showDiscountRate,
	className = text.uppercaseS,
}: DiscountRateProps) {
	const shouldShowDiscount: boolean = showDiscount && Boolean(discountText)

	if (!shouldShowDiscount || !showDiscountRate) {
		return null
	}

	const isHightlighted: boolean = channel === Channel.Outlet

	return (
		<div
			data-testid='discount-rate'
			className={cx(
				styles.discountRate,
				styles[align],
				isHightlighted && styles.hightlightedDiscount,
				!horizontal && styles.vertical
			)}
		>
			<span className={className}>{discountText}</span>
		</div>
	)
}
