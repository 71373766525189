'use client'

import { useOutsideClick } from 'hooks/useOutsideClick/useOutsideClick'
import {
	type ForwardedRef,
	type MutableRefObject,
	type ReactElement,
	cloneElement,
	forwardRef,
	useCallback,
	useEffect,
	useImperativeHandle,
	useMemo,
	useRef,
	useState,
} from 'react'
import { createPortal } from 'react-dom'
import { cx } from 'utils/cx'

import { OverTopLayer } from '../Modal/OverTopLayer/OverTopLayer'
import {
	getBottomStyles,
	getLeftStyles,
	getRightStyles,
	getTopStyles,
} from './utils/tooltipStyles'
import {
	handleKeyDown,
	onTriggerChange,
	updateTooltipPosition,
} from './utils/utils'

import styles from './Tooltip.module.scss'

/**
 * Minimum distance to the edge of the window
 */
const MIN_DISTANCE_BOUNDARY = 10

export type Position = 'bottom' | 'top' | 'left' | 'right'

export interface TooltipProps {
	readonly id?: string
	readonly content: string | React.ReactNode
	readonly position?: Position
	readonly offset?: number
	readonly boundary?: {
		current?: HTMLElement | null
	}
	readonly asChildOf?: HTMLElement
	readonly key?: number | string
	readonly children?: ReactElement
	readonly className?: string
	readonly onOpen?: () => void
	readonly onClose?: () => void
	readonly preventHover?: boolean
	readonly reverse?: boolean
}

export interface TooltipHandle {
	open: () => void
	close: () => void
	toggle: () => void
}

// Given useOutsideClick hook is conditionally needed it has been moved to this component to use
// it only if the tooltip has `preventHover`
const OnClickOutsideListener = ({
	tooltipRef,
	handleClose,
}: {
	readonly tooltipRef: MutableRefObject<HTMLDivElement | null>
	readonly handleClose: () => void
}) => {
	useOutsideClick(tooltipRef, () => handleClose())
	return null
}

export const Tooltip = forwardRef<TooltipHandle, TooltipProps>(
	(tooltipProps: TooltipProps, ref: ForwardedRef<TooltipHandle | null>) => {
		const {
			asChildOf = null,
			boundary,
			children,
			className,
			content,
			id,
			key,
			offset = 0,
			position = 'bottom',
			preventHover = false,
			onClose = () => {
				/* do nothing */
			},
			onOpen = () => {
				/* do nothing */
			},
			reverse = false,
		} = tooltipProps

		const [show, setShow] = useState(false)
		const [limits, setLimits] = useState({
			left: MIN_DISTANCE_BOUNDARY,
			right:
				typeof document !== 'undefined'
					? document.body.clientWidth - MIN_DISTANCE_BOUNDARY
					: 0,
		})
		const [tooltipStyles, setTooltipStyles] = useState({})
		const [arrowStyles, setArrowStyles] = useState({})

		/**
		 * @constant @type {React.MutableRefObject}
		 */
		const triggerRef = useRef<HTMLDivElement | null>(null)

		/**
		 * @constant @type {React.MutableRefObject}
		 */
		const tooltipRef = useRef<HTMLDivElement | null>(null)

		/**
		 * Listens the resize event in order to change the trigger element dimensions
		 */
		useEffect(() => {
			const handleTriggerChange = () => {
				onTriggerChange({
					boundary,
					setLimits,
					minDistance: MIN_DISTANCE_BOUNDARY,
				})
			}

			handleTriggerChange()

			window.addEventListener('resize', handleTriggerChange)
			return () => window.removeEventListener('resize', handleTriggerChange)
		}, [boundary, show])

		/**
		 * @constant positions Methods to execute depending on the given position
		 */
		const positions = useMemo(
			() => ({
				bottom: getBottomStyles,
				top: getTopStyles,
				left: getLeftStyles,
				right: getRightStyles,
			}),
			[]
		)

		/**
		 * Update position and dimensions of the tooltip
		 */
		const updateTooltip = useCallback(() => {
			updateTooltipPosition({
				show,
				tooltipRef,
				triggerRef,
				position,
				offset,
				limits,
				positions,
				reverse,
				setArrowStyles,
				setTooltipStyles,
			})
		}, [limits, offset, position, positions, show])

		/**
		 * Update tooltip position and dimensions
		 */
		useEffect(() => {
			updateTooltip()
		}, [updateTooltip])

		/**
		 * Handles on open event
		 */
		function handleOpen() {
			setShow(true)
			onOpen()
		}

		/**
		 * Handles on close event
		 */
		function handleClose() {
			setShow(false)
			onClose()
		}

		/**
		 * Exposes the API in component reference
		 */
		useImperativeHandle(ref, () => ({
			/**
			 * Open the tooltip
			 */
			open: handleOpen,
			/**
			 * Close the tooltip
			 */
			close: handleClose,
			/**
			 * Toggle the tooltip
			 */
			toggle: show ? handleClose : handleOpen,
		}))

		const tooltipBody = (
			<div
				id={id}
				className={cx(
					className,
					!Object.keys(tooltipStyles).length && styles.tooltipHidden
				)}
				role='tooltip'
			>
				<div className={styles.content} style={tooltipStyles} ref={tooltipRef}>
					{content}
				</div>
				<div className={styles.arrow} style={arrowStyles}></div>
			</div>
		)

		const tooltipContainer = asChildOf ? (
			createPortal(tooltipBody, asChildOf)
		) : (
			<OverTopLayer>{tooltipBody}</OverTopLayer>
		)

		return (
			<>
				{cloneElement(children as ReactElement, {
					onMouseEnter: !preventHover ? handleOpen : undefined,
					onMouseLeave: !preventHover ? handleClose : undefined,
					onFocus: !preventHover ? handleOpen : undefined,
					onBlur: handleClose,
					onKeyDown: (e: React.KeyboardEvent) => {
						handleKeyDown({ handleClose, handleOpen, e, show })
					},
					tabIndex: 0,
					ref: triggerRef,
					key,
				})}

				{preventHover && show && (
					<OnClickOutsideListener
						tooltipRef={tooltipRef}
						handleClose={handleClose}
					/>
				)}
				{show && tooltipContainer}
			</>
		)
	}
)

Tooltip.displayName = 'Tooltip'
