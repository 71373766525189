'use client'

import { useMasterData } from 'master-data/hooks/useMasterData/useMasterData'
import { useRef } from 'react'
import { useResponsive } from 'responsive/hooks/useResponsive/useResponsive'
import type { CountryConfiguration } from 'types/countryConfiguration'
import { Channel } from 'types/masterData'

import { sendPriceAnalytics } from '../../analytics/sendPriceAnalytics'
import { getShouldShowExtraInfo } from '../../utils/getShouldShowExtraInfo/getShouldShowExtraInfo'
import { SheetPricesHistory } from './SheetPricesHistory/SheetPricesHistory'
import { TooltipPricesHistory } from './TooltipPricesHistory/TooltipPricesHistory'

export type ExtraInfoProps = {
	readonly hideSaleOrPromoPrice: boolean
	readonly pricesHistory: number[]
	readonly shouldShowDiscount: boolean
	readonly horizontal: boolean
	readonly crossedOut: number[]
	readonly country: CountryConfiguration
	readonly showDiscountInfo: boolean
}

export type PricesHistoryComponentProps = {
	readonly pricesHistory: number[]
	readonly shouldShowDiscount: boolean
	readonly horizontal: boolean
	readonly sendAnalytics: () => void
}

export function ExtraInfo({
	hideSaleOrPromoPrice,
	pricesHistory,
	shouldShowDiscount,
	horizontal,
	crossedOut,
	country,
	showDiscountInfo,
}: ExtraInfoProps) {
	const { isSmall } = useResponsive()
	const { isApp, channel } = useMasterData()
	const analyticsSent = useRef(false)
	const { hasPriceHistory } = country

	if (isApp) {
		return null
	}

	const showExtraInfo = hideSaleOrPromoPrice || showDiscountInfo

	const shouldShowExtraInfo = getShouldShowExtraInfo({
		channel,
		crossedOut,
		hasPriceHistory,
		showExtraInfo,
		shouldShowDiscount,
	})

	if (!shouldShowExtraInfo) {
		return null
	}

	const isHightlighted: boolean = channel === Channel.Outlet

	const sendAnalytics = () => {
		if (!analyticsSent.current) {
			analyticsSent.current = true
			sendPriceAnalytics(
				pricesHistory,
				hideSaleOrPromoPrice,
				isHightlighted,
				channel
			)
		}
	}

	const pricesHistoryProps: PricesHistoryComponentProps = {
		pricesHistory,
		shouldShowDiscount,
		horizontal,
		sendAnalytics,
	}

	return isSmall ? (
		<SheetPricesHistory {...pricesHistoryProps} />
	) : (
		<TooltipPricesHistory {...pricesHistoryProps} />
	)
}
