'use client'

import { MODAL_TYPE, Modal, type ModalAPI } from 'fukku/Modal'
import { IconInfoL } from 'icons/components/IconInfoL'
import { useLabels } from 'labels/hooks/useLabels/useLabels'
import { useMasterData } from 'master-data/hooks/useMasterData/useMasterData'
import { useRef } from 'react'
import { cx } from 'utils/cx'

import { BodyContent } from '../Content/BodyContent'
import { PRICES_TOOLTIP_TITLE } from '../Content/keys'
import type { PricesHistoryComponentProps } from '../ExtraInfo'
import { ExtraInfoButton } from '../ExtraInfoButton'

import styles from './SheetPricesHistory.module.scss'

export function SheetPricesHistory({
	pricesHistory,
	shouldShowDiscount,
	horizontal,
	sendAnalytics,
}: Readonly<PricesHistoryComponentProps>) {
	const modalRef = useRef<ModalAPI>(null)

	const { channel } = useMasterData()
	const { t } = useLabels()

	const handleOnClick = () => {
		sendAnalytics()
		modalRef.current?.show()
	}

	return (
		<div className={styles.container}>
			<ExtraInfoButton handleOnClick={handleOnClick} horizontal={horizontal} />
			<div
				className={cx(styles.icon, !horizontal && styles.iconVertical)}
				tabIndex={-1}
			>
				<IconInfoL width={12} height={12} />
			</div>
			<Modal
				name='pricesHistorySheet'
				ref={modalRef}
				type={MODAL_TYPE.SHEET}
				title={t(PRICES_TOOLTIP_TITLE)}
				showTitle
			>
				<BodyContent
					channel={channel}
					getLabel={t}
					pricesHistory={pricesHistory}
					shouldShowDiscount={shouldShowDiscount}
				/>
			</Modal>
		</div>
	)
}
